<script lang="ts">
	import { onMount, tick } from "svelte";
	import ky from "ky";
	import { Button, Field, Input, Modal, Notification } from "@wagich/svelma";
	import { FluentProvider, Localized } from "@nubolab-ffwd/svelte-fluent";
	import { create, enforce, test, group, skip } from "vest";
	import "vest/enforce/email";

	import { bundles, localize } from "@/localization";
	import SvgIcon from "@/components/svg-icon.svelte";
	import { trackEvent } from "@/analytics";
	import { ValidatingStore } from "@/validating-store";
	import type { ContactRequest } from "@/models";
	import { FormState } from "@/utils";

	const initialFormData: ContactRequest = {
		fullname: "",
		email: "",
		phone: "",
		address: "",
		postalCode: "",
		city: "",
		message: ""
	};

	let state: FormState = FormState.Ready;
	let token: string = "";

	const store = new ValidatingStore<ContactRequest>(initialFormData, (data: ContactRequest) => {
		test("fullname", $localize("contactform-fullname.error-required"), () => {
			enforce(data.fullname).isNotBlank();
		});
		test("email", () => {
			enforce(data.email)
				.message($localize("contactform-email.error-required"))
				.isNotBlank()
				.message($localize("contactform-email.error-format"))
				.isEmail();
		});
		test("phone", $localize("contactform-phone.error-required"), () => {
			enforce(data.phone).isNotBlank();
		});
		test("message", $localize("contactform-message.error-required"), () => {
			enforce(data.message).isNotBlank();
		});
	});

	onMount(() => {
		let tokenElement = document.getElementById("RequestVerificationToken");
		if (tokenElement == null || tokenElement.textContent == null) {
			throw new Error("Could not load XSRF token.");
		}
		token = JSON.parse(tokenElement.textContent);
	});

	async function submitAsync() {
		if (state !== FormState.Ready) {
			return;
		}
		if (!store.validate()) {
			return;
		}

		try {
			state = FormState.Loading;
			await ky.post(`/api/contact`, {
				headers: { RequestVerificationToken: token },
				json: {
					sourcePath: window.location.pathname,
					sourceTitle: document.title,
					language: document.documentElement.lang,
					...store.getData()
				}
			});
			state = FormState.Success;
			store.clear();
		} catch (e) {
			state = FormState.Error;
		}
	}
</script>

<FluentProvider {bundles}>
	<div class="contactform">
		<div class="title is-family-secondary has-text-primary">
			<Localized id="contactform-title" />
		</div>
		<div class="field">
			<label for="fullname" class="label is-small">
				<Localized id="contactform-fullname" />
			</label>
			<input id="fullname" class="input" type="text" bind:value={$store.fullname.value} on:blur={() => $store.fullname.touch()} class:is-danger={$store.fullname.hasErrors} autocomplete="name" />
			{#each $store.fullname.errors as error}
				<p class="help is-danger">{error}</p>
			{/each}
		</div>
		<div class="columns">
			<div class="column is-6">
				<div class="field">
					<label for="email" class="label is-small">
						<Localized id="contactform-email" />
					</label>
					<input id="email" class="input" type="email" bind:value={$store.email.value} on:blur={() => $store.email.touch()} class:is-danger={$store.email.hasErrors} autocomplete="email" />
					{#each $store.email.errors as error}
						<p class="help is-danger">{error}</p>
					{/each}
				</div>
			</div>
			<div class="column is-6">
				<div class="field">
					<label for="phone" class="label is-small">
						<Localized id="contactform-phone" />
					</label>
					<input id="phone" class="input" type="text" bind:value={$store.phone.value} on:blur={() => $store.phone.touch()} class:is-danger={$store.phone.hasErrors} autocomplete="tel" />
					{#each $store.phone.errors as error}
						<p class="help is-danger">{error}</p>
					{/each}
				</div>
			</div>
		</div>

		<div class="field">
			<label for="address" class="label is-small">
				<Localized id="contactform-address" />
			</label>
			<input id="address" class="input" type="text" bind:value={$store.address.value} on:blur={() => $store.address.touch()} class:is-danger={$store.address.hasErrors} autocomplete="address-line1" />
			{#each $store.address.errors as error}
				<p class="help is-danger">{error}</p>
			{/each}
		</div>
		<div class="columns">
			<div class="column is-4">
				<div class="field">
					<label for="postalCode" class="label is-small">
						<Localized id="contactform-postalcode" />
					</label>
					<input id="postalCode" class="input" type="text" bind:value={$store.postalCode.value} on:blur={() => $store.postalCode.touch()} class:is-danger={$store.postalCode.hasErrors} autocomplete="postal-code" />
					{#each $store.postalCode.errors as error}
						<p class="help is-danger">{error}</p>
					{/each}
				</div>
			</div>
			<div class="column is-8">
				<div class="field">
					<label for="city" class="label is-small">
						<Localized id="contactform-city" />
					</label>
					<input id="city" class="input" type="text" bind:value={$store.city.value} on:blur={() => $store.city.touch()} class:is-danger={$store.city.hasErrors} autocomplete="address-level2" />
					{#each $store.city.errors as error}
						<p class="help is-danger">{error}</p>
					{/each}
				</div>
			</div>
		</div>

		<div class="field">
			<label for="message" class="label is-small">
				<Localized id="contactform-message" />
			</label>
			<textarea id="message" class="textarea" bind:value={$store.message.value} on:blur={() => $store.message.touch()} class:is-danger={$store.message.hasErrors} />
			{#each $store.message.errors as error}
				<p class="help is-danger">{error}</p>
			{/each}
		</div>
		{#if state === FormState.Ready || state === FormState.Loading}
			<Button type="is-primary" loading={state === FormState.Loading} on:click={submitAsync}>
				<Localized id="contactform-submit" />
			</Button>
		{:else if state === FormState.Success}
			<div class="notification is-success mt-0">
				<Localized id="contactform-success" />
			</div>
		{:else if state === FormState.Error}
			<div class="notification is-danger mt-0">
				<Localized id="contactform-error" />
			</div>
		{/if}
	</div>
</FluentProvider>
