import { FluentBundle, FluentResource } from "@fluent/bundle";
import { negotiateLanguages } from "@fluent/langneg";

import translationsDe from "../Localization/de-CH.ftl?raw";
import translationsFr from "../Localization/fr-CH.ftl?raw";

const resources = {
	de: new FluentResource(translationsDe),
	fr: new FluentResource(translationsFr),
};
const supportedLocales = Object.keys(resources);

const selectedLocales = negotiateLanguages([document.documentElement.lang, ...navigator.languages], supportedLocales, {
	defaultLocale: "de",
	strategy: "lookup"
}) as ("de" | "fr")[];

export const bundles = selectedLocales.map(locale => {
	const bundle = new FluentBundle(locale);
	bundle.addResource(resources[locale]);
	return bundle;
});

// horrible hack to get a store outside of any svelte context
import { CachedSyncIterable } from "cached-iterable";
import { initStores } from "@nubolab-ffwd/svelte-fluent";
import { writable } from "svelte/store";
class CachedSyncIterableWrapper<T> {
	private iterable: CachedSyncIterable<T>;

	constructor(iterable: CachedSyncIterable<T>) {
		Object.defineProperty(this, 'iterable', { value: iterable, enumerable: false });
	}

	[Symbol.iterator]() {
		return this.iterable[Symbol.iterator]();
	}
}
let cachedBundles = new CachedSyncIterableWrapper(CachedSyncIterable.from(bundles));
export const { localize } = initStores(writable(cachedBundles), msg => { });
